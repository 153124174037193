import React, { useCallback, useEffect, useRef } from "react"
import Seo from "../../components/SEO"
import { Link, graphql, useStaticQuery } from "gatsby"
import StarImage from "../../assets/img/star.png"
import ClutchLogo from "../../assets/img/clutch-new.png"
import bannerImageLeft from "../../assets/img/ppcBanner-left.png"
import bannerImageRight from "../../assets/img/ppcBanner-right.png"
import gradBg from "../../assets/img/grad-bg.webp"
import Love from "../../assets/img/love-icon.inline.svg"
import NoteIcon from "../../assets/img/note.inline.svg"
import InterviewIcon from "../../assets/img/interview.inline.svg"
import HireDevelopersIcon from "../../assets/img/hireDevelopers.inline.svg"
import EasyScaleUp from "../../assets/img/easyScaleUp.inline.svg"
import ListTick from "../../assets/img/list-tick.png"
import { StaticImage } from "gatsby-plugin-image"
import { useContextValues } from "../../context/NavBarContext"
import ProjectPortfolio from "../../components/PPC/Portfolio/PortfolioList"


import TestimonialSlider from "../../components/DashboardTestimonials/TestimonialSlider"
import Header from "../../components/PPC/HeaderPpcLanding"
import Faq from "../../components/PPC/ReactDevPpc/Faq"
import CorporateLocs from "../../components/CorporateLocs"
import ContactForm from "../../components/PPC/ContactFormLanding"
import { Stream, StreamPlayerApi } from "@cloudflare/stream-react"
import useIsInViewport from "../../helpers/useIsInViewport"
import AgencyReviewSlider from "../../components/AgencyReviewSlider"
import animatedIcon from "../../assets/img/Contact-us-animation4.gif"
import animatedIconWhite from "../../assets/img/Contact-us-animation-white.gif"
import SocialMediaIcons from "../../components/SocialMediaIcons"

const PPCLanding = () => {
    const {
        allHireFlutterDevelopersPpcFaqJson: { faq },
    } = useStaticQuery(graphql`
        query {
          allHireFlutterDevelopersPpcFaqJson {
            faq: nodes {
              key
              question
              answer
            }
          }
        }
      `)

    const videoRef = useRef<StreamPlayerApi>()
    const element = useRef<HTMLDivElement>(null)

    const { isMobile, changeCurrentPath } = useContextValues()
    const isInView = useIsInViewport(element!)

    const playVideo = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.play()
        }
    }, [videoRef])

    const pauseVideo = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.pause()
        }
    }, [videoRef])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isInView) {
                playVideo()
            }
            if (!isInView) {
                pauseVideo()
            }
        }, 1000)

        return (() => {
            clearTimeout(timeoutId)
        })
    }, [isInView, playVideo, pauseVideo])

    useEffect(() => {
        changeCurrentPath(location.pathname)
    }, [])

    const goToSection = (id) => {
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return (
        <>
            <Header />
            <section className="pt-[7.625rem] md:pt-24 lg:pt-40 bg-[#FFFDFB] relative">
                <div className="container max-[480px]:px-[15px]">
                    <div className="max-w-[51.625rem] mx-auto text-center">
                        <h1 className="text-black text-[2.25rem] md:text-[2.75rem] lg:text-[3.25rem] leading-[3.125rem] lg:leading-[3.981rem] pb-[1.688rem] md:pb- lg:pb-5 font-blancomedium">
                            <span>Hire <span className="text-[#4BD965] italic"> top 3% </span> Flutter developers to supercharge your development.</span>
                        </h1>
                        <p className="text-base md:text-lg">
                            Techuz provides vetted Flutter developers on-demand to meet your development needs.
                        </p>
                        <ul className="mt-10 max-md:flex max-md:flex-wrap max-md:flex-col max-md:items-center">
                            <li className="inline-block px-[0.75rem] py-[11px] border border-[#B7B7B7] rounded-[10px] md:mr-5 max-md:mb-5 max-md:w-[290px]">
                                <span className="inline-block bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] align-middle mr-2"></span>
                                <span className="inline-block align-middle text-sm leading-[21px]">NDA protected contract</span>
                            </li>
                            <li className="inline-block px-[0.75rem] py-[11px] border border-[#B7B7B7] rounded-[10px] md:mr-5 max-md:mb-5 max-md:w-[290px]">
                                <span className="inline-block bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] align-middle mr-2"></span>
                                <span className="inline-block align-middle text-sm leading-[21px]">100% Own Team, No Sub Contract</span>
                            </li>
                            <li className="inline-block px-[0.75rem] py-[11px] border border-[#B7B7B7] rounded-[10px] max-md:w-[290px]">
                                <span className="inline-block bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] align-middle mr-2"></span>
                                <span className="inline-block align-middle text-sm leading-[21px]">1 Week free trial</span>
                            </li>
                        </ul>
                        <button
                            onClick={() => goToSection('contact-us-trial')}
                            className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium mt-10 mb-[1.875rem] max-md:mb-0 group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase"
                        >
                            Get a Free 30 Minutes Consultation
                        </button>
                        <div className=" mt-7 md:mt-0">
                            <button
                                onClick={() => goToSection('our-work')}
                                className=" text-lg font-gorditamedium underline relative before:content-[''] before:w-[7px] before:h-[7px] before:bg-[#FF3D2E] before:rounded-full before:absolute before:left-0 before:top-[9px] pl-[17px] inline-block"
                            >
                                Our Work
                            </button>
                            <button
                                onClick={() => goToSection('pricing')}
                                className=" text-lg font-gorditamedium underline relative before:content-[''] before:w-[7px] before:h-[7px] before:bg-[#FF3D2E] before:rounded-full before:absolute before:left-0 before:top-[9px] pl-[17px] inline-block ml-9"
                            >
                                Pricing
                            </button>
                        </div>

                        <div className="text-center text-xs leading-[0.938rem] mt-5 max-md:mb-[3.125rem] md:mt-[1.625rem] flex items-center gap-2 flex-wrap align-middle justify-center xl:absolute left-[50%] xl:translate-x-[-50%] bottom-[30px]">
                            <span className="text-sm md:text-base">4.9/5</span>
                            <span className="flex flex-nowrap items-center gap-[0.188rem]">
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                            </span>
                            <span>based on <em className="font-gorditamedium not-italic">41</em> verified reviews</span>
                            <img
                                src={ClutchLogo}
                                alt="Clutch"
                                className="max-w-[3.75rem]"
                            />
                        </div>
                    </div>
                    <div className="banner-bottom-images flex flex-wrap justify-between md:mt-7 lg:mt-10 max-xl:justify-center max-[767px]:items-baseline">
                        <div className="left-images">
                            <img
                                src={bannerImageLeft}
                                alt="Web Developers"
                                className="inline-block h-full max-lg:h-auto max-[767px]:w-[185px] max-[402px]:w-[165px]"
                            />
                        </div>
                        <div className="right-images">
                            <img
                                src={bannerImageRight}
                                alt="Web Developers"
                                className="inline-block h-full max-lg:h-auto max-[767px]:w-[185px] max-[402px]:w-[165px]"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#FFFDFB] lg:pt-[6.125rem] lg:pb-[6.125rem] md:pb-11 pb-9 md:pt-[70px] pt-12">
                <div className="container max-w-[65rem] xl:px-0 relative z-10 flex items-center justify-center flex-wrap flex-col">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-8 font-blancomedium">We’re a fun, friendly, and<span className="text-[#4BD965] italic"> professional team</span> to work with.</h2>
                        <p className="text-lg">And we've been delivering world-class software for over 10+ years.</p>
                    </div>
                    <div className="mt-[2.875rem] max-w-[65rem] xl:px-0 relative z-10 flex items-center justify-center flex-wrap w-full md:w-[47.313rem] md:h-[27.375rem] max-[567]:h-[220px]">
                        <div ref={element} className="relative w-full h-full border border-primary p-3 rounded-3xl">
                            <span className="inline-block w-full h-full absolute left-0 top-0 p-3">
                                <StaticImage src="../assets/img/video-image-main2.webp" alt="Techuz Infoweb work environment" className="-z-10 h-full w-full rounded-2xl" />
                            </span>
                            <Stream
                                src={`https://customer-4iwgkzn69idft9ab.cloudflarestream.com/9aa1e5d252756e8160aa8a59e3343b17/iframe`}
                                className="h-full w-full top-0 left-0 overflow-hidden rounded-2xl p-0"
                                streamRef={videoRef}
                                responsive
                                title="Techuz Infoweb work environment"
                            />
                        </div>
                    </div>
                    <div className="btn-row text-center mt-[50px]">
                        <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Hire Flutter Developers</button>
                    </div>
                </div>
            </section>
            <section className="team-skills bg-[#F4F5F5] py-10 lg:py-[6.875rem]">
                <div className="container">
                    <div className="title">
                        <h2 className=" text-3xl  md:text-4xl lg:text-[2.625rem] leading-9 md:leading-[3.216rem] text-center lg:max-w-[51.75rem] mx-auto"><span className="text-[#4BD965] italic">Maximise your results </span> with highly-trained Flutter expertise.
                        </h2>
                        <ul className="md:max-w-[548px] mx-auto mt-5 mb-10">
                            <li className="text-lg leading-7 md:mb-[0.625rem] relative pl-[1.625rem] inline-block max-md:mb-4">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span>Proficiency in working with Flutter SDK and Dart scripts.
                            </li>
                            <li className="text-lg leading-7 md:mb-[0.625rem] relative pl-[1.625rem] inline-block">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span>Have the experience to make your Flutter app cloud-powered.
                            </li>
                            <li className="text-lg leading-7 md:mb-[0.625rem] relative pl-[1.625rem] inline-block">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span>Competencies in integrating third-party APIs and RESTful APIs.
                            </li>
                        </ul>
                        <ul className="team-list grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-[1.438rem] gap-[15px] mt-[2.188rem] lg:max-w-[61.25rem] mx-auto">
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-firebase.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + Firebase</span>
                            </li>
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/Flutter-node.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + Node.js</span>
                            </li>
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-mongodb.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + MongoDB</span>
                            </li>
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-supabase.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + Supabase</span>
                            </li>


                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-aws.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + AWS Amplify</span>
                            </li>
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-python.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + Python</span>
                            </li>
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-fastAPI.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + FastAPI</span>
                            </li>
                            <li className="py-4 px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent">
                                <StaticImage src="../../assets/img/flutter-mysql.webp" alt="Techuz Infoweb work environment" className="max-w-[84px] h-[34px]" objectFit="contain" />
                                <span className="block leading-6 max-md:w-full max-md:pt-[10px] mt-[12px] text-base font-gorditamedium">Flutter + MySQL</span>
                            </li>
                        </ul>
                        <div className="btn-row text-center mt-[40px] md:mt-[70px]">
                            <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Schedule A Developer's Interview</button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="technical-stack" className="py-10 md:py-12 lg:pt-[110px] lg:pb-[110px]">
                <div className="container max-md:p-0">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto max-md:p-5">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Our Flutter developers are <span className="text-[#4BD965] italic">well-versed </span> in the Flutter ecosystem.</h2>
                    </div>
                    <div className="stack-wrapper mt-[1.875rem]">
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full ">Frameworks</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Flutter</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Dart plugins</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Flutter SDK</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Dart SDK</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 rounded-lg md:mb-[1.688rem] max-md:px-0 md:flex">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Tools & Utilities</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Android Studio</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Visual Studio Code</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">IntelliJ</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Version control</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">tools (Git, GitLab, Bit Bucket)</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Databases</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Hive</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">ObjectBox</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">SQLite </span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Firebase realtime database</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Firestore </span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Sembast</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">StorageMoor</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Plugins & Integrations</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Firebase (All service)</span>
                                <span className="pr-3 mr-2 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Provider</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Payment Gateways (Stripe, Braintree, Google Pay, Razorpay)</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Social Sign-In (Facebook, Google, Apple)</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:max-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">CI/CD Tools</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Travis</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Cirrus</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Codemagic</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Bitrise</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Fastlane</span>
                            </p>
                        </div>
                    </div>
                    <div className="btn-row text-center mt-[50px]">
                        <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Schedule A Developer's Interview</button>
                    </div>
                </div>
            </section>

            <section className="hiring-steps bg-[#F8F7F3] py-6 md:py-10 lg:py-[6.875rem] overflow-hidden">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Hire Flutter developers <span className="text-[#4BD965] italic"> on-demand </span> in 4 easy steps.</h2>
                        <p className="text-lg">Hire Techuz's expert React developers on-demand to meet your project's needs. Our skilled professionals seamlessly integrate into your team, providing flexible and specialized support whenever you need it. Here's how it works:</p>
                    </div>
                    <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                <NoteIcon />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">1. Share your team requirements</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Let us know your project needs, skill sets required, and any specific expertise necessary for your development team.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                <InterviewIcon />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">2. Interview</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Conduct interviews with our skilled developers to ensure a perfect match for your project.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                <HireDevelopersIcon />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">3. Select and hire developers</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Choose the developers who best fit your requirements, and we'll handle the onboarding process seamlessly.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0 bg-opacity-100 lg:max-w-[15.75rem]">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                <EasyScaleUp />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">4. Easy scale up or down as per your requirements</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Adjust your development team's size and skill set effortlessly, responding to project demands and ensuring optimal flexibility.</p>
                        </li>
                    </ul>
                    <div className="btn-row text-center mt-[50px]">
                        <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Schedule A Developer's Interview</button>
                    </div>
                </div>
            </section>

            <section id="pricing" className="py-10 md:py-12 lg:py-[6.875rem]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-[50px] font-blancomedium">Assemble your Flutter team starting at an <br className="max-lg:hidden" /><span className="text-[#4BD965] italic">affordable price</span><span className="inline-block text-orangecol">.</span></h2>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.875rem] mt-[3.125rem] text-center lg:max-w-[960px] mx-auto">
                        <div className="py-12 px-[3.625rem] border-2 border-[#FFF2E3] rounded-[1.25rem]">
                            <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire on an hourly basis</span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[5.375rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$18</span>/Hour</div>

                            <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                                <li className="relative pl-7 mb-[0.625rem] ">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Dedicated Pay only for hours worked
                                </li>
                                <li className="relative pl-7">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Ideal if looking for time and material model
                                </li>
                            </ul>
                            <button onClick={() => goToSection('contact-us-trial')} className="inline-flex gap-5 items-center bg-transparent justify-start inline-white text-black border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium mt-10 mb-0 group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-[#3FB955] hover:text-[#fff] uppercase">
                                Get Started
                            </button>
                        </div>

                        <div className="py-12 px-[3.625rem] border-2 border-[#F8F7F3] rounded-[1.25rem] bg-[#F8F7F3]">
                            <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire at a fixed monthly rate</span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[0.625rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$2800</span>/Month</div>
                            <span className="font-gorditamedium inline-block text-sm text-white bg-black italic py-1 pl-[0.813rem] pr-[1.063rem] rounded-[6.25rem] mb-10 leading-[1.813rem]">~ Save 10%</span>
                            <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                                <li className="relative pl-7 mb-[0.625rem]">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 160 man-hours per month dedicated
                                </li>
                                <li className="relative pl-7">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Ideal if you need dedicated developers
                                </li>
                            </ul>
                            <button onClick={() => goToSection('contact-us-trial')} className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium mt-10 mb-0 group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase">
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact-us-trial" className="py-10 md:py-12 lg:py-[55px] lg:pt-[110px] bg-[#FFFDFB]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-50 m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-[1.875rem] md:pb-[3.125rem] font-blancomedium">Onboard a developer with<span className="text-[#4BD965] italic"> 1 week risk-free trial.</span></h2>
                    </div>
                    <div className="contact-form-part lg:max-w-[852px] lg:mx-auto grid md:grid-cols-[295px_1fr] rounded-[36px] border-[2px] border-[#FFF2E3]">
                        <div className="py-10 px-[2.188rem] relative z-10">
                            <span className="inline-block absolute left-0 top-0 h-full w-full -z-[1]">
                                <img
                                    src={gradBg}
                                    alt="Web Developers"
                                    className="inline-block w-full h-full max-md:rounded-t-[36px] max-md:rounded-b-[36px]"
                                />
                            </span>
                            <p className=" text-lg leading-[1.875rem] mb-10">We ensure you are matched with the right resource based on your requirements.</p>
                            <ul>
                                <li className="relative pl-12 mb-10">
                                    <img
                                        src={ListTick}
                                        alt="tick icon"
                                        className="inline-block md:w-8 mr-3 absolute left-0 top-0"
                                    />
                                    We will respond to you within 24 hours.
                                </li>
                                <li className="relative pl-12 mb-10">
                                    <img
                                        src={ListTick}
                                        alt="tick icon"
                                        className="inline-block md:w-8 mr-3 absolute left-0 top-0"
                                    />
                                    We’ll sign a NDA if requested.
                                </li>
                                <li className="relative pl-12">
                                    <img
                                        src={ListTick}
                                        alt="tick icon"
                                        className="inline-block md:w-8 mr-3 absolute left-0 top-0"
                                    />
                                    Access to dedicated product specialists.
                                </li>
                                <li className="flex flex-col pb-[1.875rem] md:pb-[3.125rem] md:mt-10">
                                    <h3 className="font-blancomedium text-black pb-2 text-lg">
                                        Inquires
                                        <span className="inline-block text-orangecol">.</span>
                                    </h3>
                                    <a
                                        href="mailto:sales@techuz.com"
                                        className="md:text-[1.5rem] text-xl font-gorditamedium underline leading-[1.875rem] text-black transition-all duration-500 hover:text-primary"
                                    >
                                        sales@techuz.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="form-wrapper  rounded-[2.125rem] py-10 px-[2.188rem] bg-white-200">
                            <ContactForm
                                submitBtnTxt="Submit"
                                currency={'USD'}
                                spacingCss="md:pb-[1.5rem]"
                                submitBtnCss="text-white bg-primary min-[1441px]:text-[1.25rem] max-[1440px]:text-[1.15rem] text-base text-center rounded-[1.875rem] px-3 py-4 max-[1440px]:py-[0.75rem] w-full font-gorditamedium hover:bg-orange-500 hover:text-white transition-all"
                                formCss="rounded-xl"
                                NameCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                emailCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                phoneNumberCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                budgetCss="select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] appearance-none !text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 !px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                textAreaCss="align-top h-40 max-[1440px]:h-24 resize-none !text-sm w-full text-black py-3 !px-5 bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                formTitle="Get in touch with technical experts."
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section id="our-work" className="lg:pt-[50px] lg:pb-[50px] md:pb-11 pb-0">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-8 font-blancomedium">Projects<span className="text-[#4BD965] italic"> powered by</span> our development team.</h2>
                    </div>
                    <ProjectPortfolio />
                </div>
            </section>


            {!isMobile && <section className="py-8  lg:py-10 xl:py-20 testimonial-sec md:py-[1.875rem]">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] px-[1.625rem] rounded-[6.25rem] mb-6 md:mb-[0.625rem] leading-[1.125rem] md:leading-[21px]">Testimonials</span></div>
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            Here’s what <span className="italic text-black md:text-primary">our clients say</span> about us
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">They’ve praised and highly rated our work on various review platforms.</p>
                    </div>

                    <TestimonialSlider />

                </div>
            </section >}

            <section className=" py-6 md:py-10 lg:py-[6.875rem]">
                <div className="container">
                    <div className="title">
                        <h2 className="text-3xl md:text-4xl lg:text-[2.625rem] leading-[3.216rem] text-center">
                            <span>Awarded for</span>
                            <span className="text-[#4BD965] italic"> best work.</span>
                        </h2>
                    </div>
                    <AgencyReviewSlider />
                </div>
            </section>
            <Faq faqData={faq} />
            <section className="contact-us py-10 md:py-20 bg-[#414142]">
                <div className="container">
                    <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
                        <ul>
                            <li className="flex flex-col pb-[1.875rem] md:pb-[3.125rem]">
                                <h3 className="font-blancomedium text-white pb-2">
                                    Inquires
                                    <span className="inline-block text-orangecol">.</span>
                                </h3>
                                <a
                                    href="mailto:sales@techuz.com"
                                    className="md:text-[1.5rem] text-xl font-gorditaregular underline leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                                >
                                    sales@techuz.com
                                </a>
                                <a
                                    href="mailto:hr@techuz.com"
                                    className="md:text-[1.5rem] text-xl font-gorditaregular underline leading-[1.875rem] text-white transition-all duration-500 hover:text-primary mt-[10px]"
                                >
                                    hr@techuz.com
                                </a>
                            </li>
                            <li className="flex flex-col pb-7">
                                <h3 className="font-blancomedium text-white pb-2">
                                    Reach us at
                                    <span className="inline-block text-orangecol">.</span>
                                </h3>
                                <div className="flex items-center pb-2">
                                    <span className="bg-[#2A2A2D] w-9 h-9 flex item-center justify-center mr-[0.625rem] rounded-full items-center">
                                        <StaticImage src="../assets/img/wp.png" alt="" />
                                    </span>
                                    <a
                                        target="_blank"
                                        href="https://wa.me/message/ZPEQ4QXOJTEDD1"
                                        className="md:text-[1.125rem] text-base font-gorditaregular leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                                    >
                                        +91 95125 59090
                                    </a>
                                    <span className="text-sm font-gorditaregular text-white ml-1">( Sales )</span>
                                </div>
                                <div className="flex items-center">
                                    <span className="bg-[#2A2A2D] w-9 h-9 flex item-center justify-center mr-[0.625rem] rounded-full items-center">
                                        <StaticImage src="../assets/img/wp.png" alt="" />
                                    </span>
                                    <a
                                        href="tel:9512559091"
                                        className="md:text-[1.125rem] text-base font-gorditaregular leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                                    >
                                        +91 95125 59091
                                    </a>
                                    <span className="text-sm font-gorditaregular text-white ml-1">( HR )</span>
                                </div>
                            </li>
                            <li className="flex flex-col md:pt-[2.625rem] pt-0 pb-[1.875rem] md:pb-0">
                                <SocialMediaIcons />
                            </li>
                        </ul>
                        <div className="max-[567px]:w-full md:w-[440px] lg:w-[660px] h-[340px] max-md:h-[270px] contact-form leading-[2.375rem] pt-10 md:pt-14 pb-10 md:pb-[4.875rem] px-[1.875rem] rounded-[1.875rem] text-center lg:px-[3.125rem] relative z-10 bg-white-200 shadow-[0_9px_0_rgba(42,42,45,1)]">
                            <h2 className="text-black text-2xl sm:text-[1.75rem] lg:text-[2.5rem] lg:leading-[3.375rem] pb-12 font-blancomedium text-center">
                                Get the <i>real estimates</i> and <br /> <i>ideal solutions</i> for your project
                                <span className="inline-block text-orangecol">.</span>
                            </h2>
                            <button
                                onClick={() => goToSection('contact-us-trial')}
                                className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 font-gorditamedium group ease-in duration-300"
                            >
                                Get an estimate
                                <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                    <img
                                        src={animatedIconWhite}
                                        alt=""
                                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                                    />
                                    <img
                                        src={animatedIcon}
                                        alt=""
                                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                                    />
                                </span>
                            </button>
                            <StaticImage src="../assets/img/tech-bg-new.webp" alt="" className="absolute w-full h-full top-0 left-0 -z-10 rounded-[1.875rem] " />
                        </div>
                    </div>
                </div>
            </section>
            <footer className="bg-[#151516] pt-14">
                <div className="text-center container">
                    <Link to="/" className="logo mb-6 w-full md:hidden block text-left">
                        <StaticImage
                            src="../assets/img/techuz-logo-white.png"
                            alt="Techuz Infoweb"
                            className="inline max-w-[6.563rem]"
                            class="relative w-auto"
                            width={105}
                            height={35.45}
                            placeholder="blurred"
                        />
                    </Link>
                    <h4 className="text-white md:text-sm text-xs flex items-center md:justify-center justify-start leading-7 md:pb-6 pb-2.5">
                        Made with
                        <Love className="mx-1" />
                        in India and serving worldwide
                    </h4>
                    <ul className="flex uppercase text-[#979797] md:justify-center justify-start text-xl font-gorditaregular flex-wrap md:flex-nowrap">
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            USA
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            UK
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            Australia
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            SINGAPORE
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            INDIA
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            UAE
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            EUROPE
                        </li>
                    </ul>
                </div>
                <CorporateLocs />
                <div className="footer-btm md:bg-[#09090A] bg-[#09090A] md:py-4 pt-4 pb-[0.875rem] md:mt-[3.75rem] mt-[1.875rem]">
                    <div className="container">
                        <div className="flex md:justify-between justify-center items-center flex-wrap md:flex-nowrap">
                            <ul className="flex items-center md:mb-0 mb-3.5">
                                <li className="leading-4 relative mr-4 pr-4 after:content[''] after:absolute after:bg-grey after:w-[0.063rem] after:h-3 after:top-1/2 after:right-0 after:translate-y-[-50%]">
                                    <Link
                                        to="/privacy-policy/"
                                        className="block text-[#808080] text-xs hover:text-white transition-all duration-500"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className="leading-4">
                                    <Link
                                        to="/terms/"
                                        className="block text-[#808080] text-xs hover:text-white transition-all duration-500"
                                    >
                                        Terms &amp; Conditions
                                    </Link>
                                </li>
                            </ul>
                            <p className="text-[#808080] text-xs md:mb-0 md:w-auto w-full text-center">
                                Copyright {new Date().getFullYear()} © Techuz InfoWeb Private
                                Limited
                            </p>
                        </div>
                    </div>
                </div>

            </footer>

        </>
    )
}
export const Head = () => <Seo title="Hire Flutter Developers" noIndex />

export default PPCLanding